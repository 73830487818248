import React from 'react'
import Link from 'gatsby-link'

import './styles/header.scss'

export default () => (
  <header>
    <div>
      <Link to='/'>
        <span>
          <h1>marklim</h1>
          <p>web developer</p>
        </span>
      </Link>
    </div>
    <div />
    <nav>
      <span role="img" title="milk" aria-label="milk">🥛</span>
      <span role="img" title="ram" aria-label="ram">🐏</span>
    </nav>
  </header>
)
